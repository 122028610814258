import * as React from "react"
import '../styles/video.scss';

const Orientation = () => {
  return (
    <video controls autoPlay muted>
        <source src='/orientation.mp4' type="video/mp4"/>
    </video>
    )
}
export default Orientation;